class Menu {

	constructor($target) {
		
		//Private properties
        $target;
		let name = "HELLO";
		var hamburgerMenu = $target.find("#hamburger_menu");
		var hamburgerCircle = $target.find(".hamburger_circle");
		var hamburgerContainer = $target.find(".hamburger_container");
		var menuMain = $target.find(".menu-mobile");
		var menuItem = $target.find(".menu-item-has-children");
		var topNav = $target.find("#top-nav-holder");
		var headerTop = $target.find("#header-top");
		var headerLogo = $target.find("#header-logo");
		var searchContainer = $target.find('.mobile_search');
		var searchMobile = $target.find('.top_nav_container__search');
		var searchBar = $target.find('#header-search-holder');
		var bgBody = document.createElement('div');	
	
		function init(){
			addEventListeners(); 
		}

		function addEventListeners(){
			//click the menu
			$(hamburgerContainer).click(toggleMenu);
			$(menuItem).click(displaySubMenuList);
			$(searchMobile).click(showSearchBar);
			$(window).resize(resize);	
		}


		// changes menu display dending on width of screen
		function resize(){
			if($(hamburgerMenu).hasClass('closed') && $(window).width() >= 992){
				$(menuMain).css('display', 'block');	
				$(topNav).css('display', 'block');	
				$(topNav).css('height', '40px');	
			} else{
				$(hamburgerMenu).removeClass('open');
				$(hamburgerContainer).removeClass('open');
				$(bgBody).remove();
				$(menuMain).css('display', 'none');	
				$(topNav).css('display', 'none');	
				$(hamburgerContainer).css('position', 'relative');	
				$(hamburgerContainer).css('z-index', 'unset');
				$(hamburgerCircle).css('display', 'none');	
				$(topNav).css('height', '0px');	
				$(headerTop).css('border-style', 'solid');
				$(headerLogo).css('opacity', '1');
				$(searchContainer).css('visibility', 'visible');
				
			}

			// if($(searchMobile).hasClass('closed') && $(window).width() >= 992){
			// 	$(searchBar).css('display', 'block');	
			// 	$('body').css('margin-top', '0px');	
			// } else{
			// 	$(searchMobile).removeClass('open');
			// 	$(searchBar).css('display', 'none');
			// 	$('body').css('margin-top', '0px');
				
			// }
		}

		// // toggling hamburger menu
		function toggleMenu(){
			$(hamburgerMenu).toggleClass('open');
			$(hamburgerContainer).toggleClass('open');
			$(bgBody).addClass('menu_overlay');
			
			if($(hamburgerMenu).hasClass('open')){
				showOverlay();
				$(menuMain).css('display', 'block');	
				$(hamburgerContainer).css('position', 'absolute');	
				$(headerTop).css('border-style', 'none');	
				$(headerLogo).css('opacity', '0.1');
				$(hamburgerContainer).css('z-index', '1');	
				$(topNav).css('display', 'block');	
				$(hamburgerCircle).css('display', 'block');	
				$(topNav).css('height', '0px');	
				$(bgBody).appendTo('body');	
				$(searchContainer).css('visibility', 'hidden');
				

			}else{
				menuClose();
			}
		}

		function menuClose(){
			$(bgBody).remove();
			$(menuMain).css('display', 'none');
			$(topNav).css('display', 'none');	
			$(hamburgerCircle).css('display', 'none');	
			$(hamburgerContainer).css('position', 'relative');	
			$(hamburgerContainer).css('z-index', 'unset');
			$(headerTop).css('border-style', 'solid');	
			$(headerLogo).css('opacity', '1');
			$(searchContainer).css('visibility', 'visible');
		}
		
		// functions for the overlay for main menu
		function showOverlay(){
			//append the overlay on the body to cover all the page 
		   	$(bgBody).appendTo('body');
		   	$(bgBody).click(hideOverlay);
		}

		function hideOverlay(){
			//if you click on the overlay close the menu and show the hidden elements
		   	$(bgBody).remove();
			$(menuMain).css('display', 'none');
			$(topNav).css('display', 'none');	
			$(hamburgerMenu).removeClass('open');
			$(hamburgerContainer).css('position', 'relative');	
			$(hamburgerContainer).css('z-index', 'unset');
			$(headerTop).css('border-style', 'solid');
			$(headerLogo).css('opacity', '1');
			$(searchContainer).css('visibility', 'visible');
			menuClose();			
		}

		function showSearchBar(){
			$(searchMobile).toggleClass('open');

			if($(searchMobile).hasClass('open')){
				$(searchBar).css('display', 'block');		
				$('body').css('margin-top', '33px');
			}else{
				hideSearch()
			}
		}
		
		function hideSearch(){
		
			$(searchBar).css('display', 'none');
			$('body').css('margin-top', '0px');
		}

		function displaySubMenuList(){
					
			var subList = $(this).find('.sub-menu');
			$(subList).toggleClass('displayList');
			
			if($(subList).hasClass('displayList')){
				$(subList).css('display', 'block');
			}
			else{
				$(subList).css('display', 'none');
			}
			
			// function showMenuOverlay(){
			// 	//append the overlay on the body to cover all the page 
			// 	$(bgMenu).appendTo('body');
			// 	$(bgMenu).click(hideMenuOverlay);
			// }
	
			// function hideMenuOverlay(){
			// 	//if you click on the overlay close the menu
			// 	$('body').css('overflow', 'auto');
			// 	$(bgMenu).remove();
			// 	$(subList).removeClass('displayList')
			// 	$(subList).css('display', 'none');
				
			// }

			// function resizeSubMenu(){
			// 	if($(subList).hasClass('displayList')){
			// 		$(subList).css('display', 'none');
			// 		$(subList).removeClass('displayList');	
			// 	}
			// }
	
			// $(window).resize(resizeSubMenu);

		}

		init();
		return {

			//Public properties
			name:name,

			publicBlockHero:function(string){

				// $target.append("HELLO!"+string);
				// console.log(name)
				// console.log(string)
				// console.log($target)

			}

		}

	}

}

export default Menu;