import '../scss/style.scss';

import 'browsernizr/test/css/rgba';
import 'browsernizr/test/file/filesystem';
import 'browsernizr/test/websockets';
import svg4everybody from 'svg4everybody/dist/svg4everybody'

import Menu from './menu';
import HomeSlider from './home-slider';

/*Site wiegets*/

// import ComponentExample from './blocks/block_example.js';
import BlockHero from './blocks/block_hero.js';
// console.log('ComponentExample', ComponentExample)
/*Modernizr*/

import Modernizr from 'browsernizr';

// Set global variables
window.WINDOW_WIDTH = $(window).width();
window.WINDOW_HEIGHT = $(window).height();
window.BREAK_XL = 1200;
window.BREAK_LG = 992;
window.BREAK_MD = 768;
window.BREAK_SM = 576;
window.BREAK_XS = 480;

$(function() {

	// Lets get the SVGs in:
	svg4everybody();

	let menu = new Menu($('#header-top'));
	let homeSlider = new HomeSlider($('#slider1-holder'));

	// Only initialise the class if the widget exists on the page.
	let blockHeroExample;
	
	$('body').find('.block').each(function(index){

		if($(this).hasClass('block_hero')){

			blockHeroExample = new BlockHero($(this));
			
			blockHeroExample.publicBlockHero("defined");
			
		}

	})
});