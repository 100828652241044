'use strict';
import { tns } from "../node_modules/tiny-slider/src/tiny-slider"

class HomeSlider {

	constructor($target) {

		//Private properties
        $target;
       
        // console.log($target.find('.boxSlider')[0])


		function init(){
			initSlider();
		}

		function initSlider(){
			$target.find('.boxSlider').each(function(){
				var slider = tns({
					container: $(this)[0],
					items: 1,
					slideBy: 'page',
					autoplay: true,
					autoplayButtonOutput:false,
					autoplayHoverPause: false,
					autoplayTimeout: 5000,
					controls:false,
					nav: true,
					navPosition: 'bottom',
					speed: 0,
					animateDelay: 5000,
					
				});
			});
            
		}

		init()
		return {

			//Public properties
			name:name,

			publicBlockHero:function(string){

				// $target.append("HELLO!"+string);
				// console.log(name)
				// console.log(string)
				// console.log($target)

			}

		}

	}

}

export default HomeSlider;