'use strict';

class BlockHero {

	constructor($target) {

		//Private properties
		$target;

		let name = "HELLO";

		return {

			//Public properties
			name:name,

			publicBlockHero:function(string){

				// $target.append("HELLO!"+string);
				console.log(name)
				console.log(string)
				console.log($target)

			}

		}

	}

}

export default BlockHero;